.terms-and-conditions {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .terms-and-conditions h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .terms-and-conditions h2 {
    font-size: 18px;
    margin-top: 20px;
  }
  
  .terms-and-conditions p {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  